<template>
	<div class="box">
		<div class="flex" style="justify-content: space-between">
			<Title title="我的资源列表" />
			<button class="add_but" @click="go_add">添加资源</button>
		</div>
		<div class="ct_box">
			<ul class="nav flex">
				<li @click="nav_tab(index)" :class="placard.pub_index == index ? 'active' : ''"
					v-for="(item, index) in nav_list" :key="index">{{ item.title }}
					<el-badge :value="item.order_num" class="item" v-if="item.order_num" :max="99">
					</el-badge>
				</li>
			</ul>
			<!-- <orderNav /> -->
			<router-view></router-view>
			<el-dialog title="被拒绝原因" :append-to-body="true" :visible.sync="Link" :show-close="false">
				<p>{{ reasonItem.reason }}</p>
			</el-dialog>
			<el-dialog title="编辑媒体" :append-to-body="true" :visible.sync="updateShow" :show-close="false">
			</el-dialog>
		</div>
	</div>
</template>

<script>
import Title from '@/components/UserInfoTitle'
// import orderNav from '@/components/order_nav'
import { mapMutations, mapState } from 'vuex'
export default {
	components: {
		Title,
		// orderNav
	},
	computed: {
		...mapState(['placard']),
		...mapMutations(['SET_PUB_INDEX'])
	},
	mounted() {
		this.getMediaNav()

	},
	data() {
		return {
			Link: false,
			reasonText: '', //申请原因
			reasonItem: {},
			updateShow: false, //编辑窗口
			updateItem: {},
			nav_list: [],
			add_url_list: ['/placard_user/add_placard/add_outdoors', '/placard_user/add_placard/add_network','/placard_user/add_placard/add_journal','/placard_user/add_placard/add_tv'],
		}
	},
	methods: {
		go_add() {
			this.$router.push({
				path: this.add_url_list[this.placard.pub_index]
			})
		},
		getMediaNav() {
			this.curlGet('/api/guanggao_info/init').then(res => {
				if (res.data.code) {
					let url_arr = ['/placard_user/pub_placard/pub_outdoors', '/placard_user/pub_placard/pub_network', '/placard_user/pub_placard/pub_journal', '/placard_user/pub_placard/pub_tv']
					// this.nav_list = res.data.data
					for (const key in res.data.data) {
						this.nav_list.push({
							id: res.data.data[key].id,
							title: res.data.data[key].title,
							path: url_arr[key - 1],
						})
					}
					// this.$store.state.UserMediaNav = res.data.data
					// this.$store.state.UserTypeItem = res.data.data[0]
				}
			})
		},
		nav_tab(index) {
			// this.$store.state.placard.pub_index = index
			this.$store.commit('SET_PUB_INDEX', index)
			this.$router.push({
				path: this.nav_list[index].path
			})

		},

	},
}
</script>

<style lang="scss" scoped>
@import '@/scss/MediaOrder';
@import '@/scss/order_nav';

::v-deep .el-input__inner {
	height: 38px;
}
</style>